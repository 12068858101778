import React, { Component } from "react";
import { connect } from "react-redux";
import "./Budget.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../../Header/Header";
import Dialog from "../../Dialog/Dialog";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%"
  },
  radioRoot: {
    display: "none",
    backgroundColor: "#FFFFFF"
  },
  label: {
    textTransform: "none"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  }
};

const title = "Travel Preferences";


const contentText = `Now we’re going to ask you questions about your travel behavior and preferences 
so that your travel experience is enjoyable (and avoid awkward situations with your travel partners). 
`;

const buttonText = `Let's go.`

class Budget extends Component {

  continue = event => {
    event.preventDefault();
    this.props.nextStep();
  };


  handleChange = name => event => {
    this.setState({ [name]: event.currentTarget.value });
    console.log(this.state);
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Dialog
            title={title}
            open={this.props.open}
            handleClose={this.props.handleClose}
            buttonText={buttonText}
            contentText={contentText}
          />
          <Header />
          <div className="budgetHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Set Budget Preferences
            </Typography>

              <p className="pageCaption">
                How do you like to allocate your money? Each traveler has their own view on money. Please tell us about your spending preferences when you’re traveling.
            </p>
            </div>
            <div autoComplete="off" className="userForms">
              {/* Flights */}
              <h5 className="userInfoLabel" id="budgetSubHeader">
                Flights
              </h5>
              <div className="radio-toolbar" row="true">
                <input
                  type="radio"
                  name="thriftyFlights"
                  id="thriftyFlights"
                  value="thrifty"
                  checked={values.flights === "thrifty"}
                  onChange={this.props.handleChange("flights")}
                />
                <label
                  htmlFor="thriftyFlights"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Thrifty</p>
                  <span className="budgetPreferenceRange">$0 - $50</span>
                </label>
                <input
                  type="radio"
                  name="moderateFlights"
                  id="moderateFlights"
                  value="moderate"
                  checked={values.flights === "moderate"}
                  onChange={this.props.handleChange("flights")}
                />
                <label
                  htmlFor="moderateFlights"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Moderate</p>
                  <span className="budgetPreferenceRange">$50 - $100</span>
                </label>
                <input
                  type="radio"
                  name="luxuryFlights"
                  id="luxuryFlights"
                  value="luxury"
                  checked={values.flights === "luxury"}
                  onChange={this.props.handleChange("flights")}
                />
                <label
                  htmlFor="luxuryFlights"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Luxury</p>
                  <span className="budgetPreferenceRange">$100+</span>
                </label>
              </div>

              {/* Accomodations */}
              <h5 className="userInfoLabel" id="budgetSubHeader">Accomodations</h5>

              <div className="radio-toolbar" row="true">
                <input
                  type="radio"
                  name="thriftyAccomodations"
                  id="thriftyAccomodations"
                  value="thrifty"
                  checked={values.accomodations === "thrifty"}
                  onChange={this.props.handleChange("accomodations")}
                />
                <label
                  htmlFor="thriftyAccomodations"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Thrifty</p>
                  <span className="budgetPreferenceRange">$0 - $50</span>
                </label>
                <input
                  type="radio"
                  name="moderateAccomodations"
                  id="moderateAccomodations"
                  value="moderate"
                  checked={values.accomodations === "moderate"}
                  onChange={this.props.handleChange("accomodations")}
                />
                <label
                  htmlFor="moderateAccomodations"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Moderate</p>
                  <span className="budgetPreferenceRange">$50 - $100</span>
                </label>
                <input
                  type="radio"
                  name="luxuryAccomodations"
                  id="luxuryAccomodations"
                  value="luxury"
                  checked={values.accomodations === "luxury"}
                  onChange={this.props.handleChange("accomodations")}
                />
                <label
                  htmlFor="luxuryAccomodations"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Luxury</p>
                  <span className="budgetPreferenceRange">$100+</span>
                </label>
              </div>

              {/* Meals */}
              <h5 className="userInfoLabel" id="budgetSubHeader">
                Meals
              </h5>
              <div className="radio-toolbar" row="true">
                <input
                  type="radio"
                  name="thriftyMeals"
                  id="thriftyMeals"
                  value="thrifty"
                  checked={values.meals === "thrifty"}
                  onChange={this.props.handleChange("meals")}
                />
                <label
                  htmlFor="thriftyMeals"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Thrifty</p>
                  <span className="budgetPreferenceRange">$0 - $50</span>
                </label>
                <input
                  type="radio"
                  name="moderateMeals"
                  id="moderateMeals"
                  value="moderate"
                  checked={values.meals === "moderate"}
                  onChange={this.props.handleChange("meals")}
                />
                <label
                  htmlFor="moderateMeals"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Moderate</p>
                  <span className="budgetPreferenceRange">$50 - $100</span>
                </label>
                <input
                  type="radio"
                  name="luxuryMeals"
                  id="luxuryMeals"
                  value="luxury"
                  checked={values.meals === "luxury"}
                  onChange={this.props.handleChange("meals")}
                />
                <label htmlFor="luxuryMeals" className="budgetPreferenceButton">
                  <p className="preferenceLabel">Luxury</p>
                  <span className="budgetPreferenceRange">$100+</span>
                </label>
              </div>

              {/* Transportation */}
              <h5 className="userInfoLabel" id="budgetSubHeader">
                Transportation
              </h5>
              <div className="radio-toolbar" row="true">
                <input
                  type="radio"
                  name="thriftyTransportation"
                  id="thriftyTransportation"
                  value="thrifty"
                  checked={values.public_transportation === "thrifty"}
                  onChange={this.props.handleChange("public_transportation")}
                />
                <label
                  htmlFor="thriftyTransportation"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Thrifty</p>
                  <span className="budgetPreferenceRange">$0 - $50</span>
                </label>
                <input
                  type="radio"
                  name="moderateTransportation"
                  id="moderateTransportation"
                  value="moderate"
                  checked={values.public_transportation === "moderate"}
                  onChange={this.props.handleChange("public_transportation")}
                />
                <label
                  htmlFor="moderateTransportation"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Moderate</p>
                  <span className="budgetPreferenceRange">$50 - $100</span>
                </label>
                <input
                  type="radio"
                  name="luxuryTransportation"
                  id="luxuryTransportation"
                  value="luxury"
                  checked={values.public_transportation === "luxury"}
                  onChange={this.props.handleChange("public_transportation")}
                />
                <label
                  htmlFor="luxuryTransportation"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Luxury</p>
                  <span className="budgetPreferenceRange">$100+</span>
                </label>
              </div>

              {/* Activities */}
              <h5 className="userInfoLabel" id="budgetSubHeader">
                Experiences
              </h5>
              <div className="radio-toolbar" row="true">
                <input
                  type="radio"
                  name="thriftyActivities"
                  id="thriftyActivities"
                  value="thrifty"
                  checked={values.activities === "thrifty"}
                  onChange={this.props.handleChange("activities")}
                />
                <label
                  htmlFor="thriftyActivities"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Thrifty</p>
                  <span className="budgetPreferenceRange">$0 - $50</span>
                </label>
                <input
                  type="radio"
                  name="moderateActivites"
                  id="moderateActivites"
                  value="moderate"
                  checked={values.activities === "moderate"}
                  onChange={this.props.handleChange("activities")}
                />
                <label
                  htmlFor="moderateActivites"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Moderate</p>
                  <span className="budgetPreferenceRange">$50 - $100</span>
                </label>
                <input
                  type="radio"
                  name="luxuryActivities"
                  id="luxuryActivities"
                  value="luxury"
                  checked={values.activities === "luxury"}
                  onChange={this.props.handleChange("activities")}
                />
                <label
                  htmlFor="luxuryActivities"
                  className="budgetPreferenceButton"
                >
                  <p className="preferenceLabel">Luxury</p>
                  <span className="budgetPreferenceRange">$100+</span>
                </label>
              </div>
              <Button
                value="submit"
                type="submit"
                size="medium"
                onClick={this.continue}
                variant="contained"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: "75px",
                  marginBottom: "25px"
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Next
                <ArrowForward classes={{ root: classes.arrowRoot }} />
              </Button>
            </div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Budget.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(Budget));

import React, { Component } from "react";
import { connect } from "react-redux";
import "./KnownDates.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { withRouter } from "react-router-dom";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import MediaQuery from "react-responsive";
import Moment from 'react-moment';

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none"
  },
  select: {
    color: "#252947",
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    margin: "0",
    padding: "0"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  }
};

class KnownDates extends Component {

  state = {
    tripDestination: "",
    departOption1: new Date(),
    returnOption1: new Date(),
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  previous = () => {
    this.props.history.push("/location");
  };

  handleNextClick = () => {
    console.log("ADDING PLANNER TO TRIP!", this.props.tripByProject);
    const history = this.props;
    this.props.dispatch({
      type: "ADD_PLANNER_TO_TRIP",
      payload: {
        trip_id: this.props.tripByProject[0].id,
        user_id: this.props.tripByProject[0].planner_id,
        username: this.props.user.username,
        historyProps: history
      }
    });
  };

  handleDateChangeFor = option => date => {
    this.setState({
      [option]: date
    });
    console.log(this.state);
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
    console.log("changing input!");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="datesHeader">
            <div className="infoDiv">

              <h5 className="userInfoLabel" style={{ width: "100%", margin: "auto", textAlign: "center" }}>Already have your timeline figured out?
              <br></br>
                Enter it in below!</h5>
            </div>
            <form autoComplete="off" className="userForms" id="dateForm">
              <h5 className="userInfoLabel" style={{ display: "inline-block" }}>Depart</h5>
              <h5 className="userInfoLabel" style={{ marginLeft: "100px", display: "inline-block" }}>Return</h5>
              <div className="App">
                <MediaQuery query="(min-width: 1024.1px)">
                  <DateRangePicker
                    orientation="horizontal"
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                  />
                </MediaQuery>
                <MediaQuery query="(max-width: 1024px)">
                  <DateRangePicker
                    orientation="vertical"
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                  />
                </MediaQuery>
              </div>
            </form>
            <Button
              size="medium"
              onClick={this.handleNextClick}
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "75px",
                marginBottom: "306px"
              }}
              classes={{ label: classes.label, root: classes.buttonRoot }}
              color="secondary"
            >
              Next
              <ArrowForward classes={{ root: classes.arrowRoot }} />
            </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

KnownDates.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tripByProject: state.tripByProject
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(KnownDates))
);

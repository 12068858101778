import React, { Component } from "react";
import { connect } from "react-redux";
import "./Travelers.css";
import Header from "../Header/Header";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";


const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  iconRoot: {
    width: "25px",
    height: "25px",
  },
  addButtonIconRoot: {
    width: "25px",
    height: "25px",
    color: '#CBCCD4',
  },
  label: {
    textTransform: "none",
  },
  buttonRoot: {
    padding: '0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#f16b52',
    display: 'inline-block',
  },
  addButtonRoot: {
    padding: '0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#CBCCD4',
    display: 'inline-block',
  },
  completeLabel: {
    textTransform: 'none',
  },
  addTravelerLabel: {
    color: '#CBCCD4',
  },
  checkMarkRoot: {
    height: '23px',
    verticalAlign: 'center',
    color: '#f16b52',
    marginTop: '10px',
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  }
};

class Travelers extends Component {
  componentDidMount() {
    // dispatch action to get users by trip id if tripByProject reducer has been set else redirect to /plan-trip
    if (this.props.tripByProject.length > 0) {
      this.props.dispatch({
        type: 'GET_USERS_BY_TRIP_ID',
        payload: {
          trip_id: this.props.tripByProject[0].id
        }
      })
    }
    else this.props.history.push("/plan-trip")
  }
  handleCompleteClick = () => {
    this.props.history.push("/success");
  };

  continue = () => {
    this.props.history.push("/success");
  };

  previous = () => {
    this.props.history.push("/location")
  }

  handleAddTravelerClick = () => {
    this.props.history.push("/add-user");
  }

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <Header />
        <MuiThemeProvider theme={theme}>
          <div className="pageHeader">

            {!this.props.usersByTrip.length ? <h1>Loading...</h1> :
              <div>
                <div className="infoDiv">
                  <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                    Travelers
                </Typography>
                  <p className="pageCaption">
                    Invite additional travelers or complete trip set-up.
                </p>
                </div>

                <div className="infoDiv">
                  {this.props.usersByTrip.map((traveler, index) =>
                    <div className="iconContainer" align="left" key={index}>
                      <AccountCircleIcon
                        classes={{ root: classes.iconRoot }}
                        className="travelerIcon"
                      />

                      <span id="userName">
                        {traveler.username}
                      </span>
                      <CheckIcon className="checkMark" classes={{ root: classes.checkMarkRoot }} />
                    </div>
                  )}
                  <Button
                    onClick={this.handleAddTravelerClick}
                    classes={{ label: classes.label, root: classes.addButtonRoot }}
                  >
                    <div className="buttonContainer" align="left">
                      <AddCircleOutline
                        classes={{ root: classes.addButtonIconRoot }}
                        className="travelerIcon"
                      />
                      <span id="addTravelerButton">Add Traveler</span>
                    </div>
                  </Button>
                </div>
                <br />
                <br />
                <Button
                  size="medium"
                  onClick={this.continue}
                  variant="contained"
                  style={{
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    margin: "auto",
                    borderRadius: "25px",
                    height: "50px",
                    width: "168px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "16px",
                    textAlign: "center"
                  }}
                  classes={{ label: classes.label }}
                  color="secondary"
                >
                  Next
                  <ArrowForward classes={{ root: classes.arrowRoot }} />
                </Button>
              </div>}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Travelers.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  trip: state.trip,
  tripByProject: state.tripByProject,
  usersByTrip: state.usersByTrip,
});

export default connect(mapStateToProps)(withStyles(styles)(Travelers));

import React, { Component } from "react";
import { connect } from "react-redux";
import "./Accomodations.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../../Header/Header";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%"
  },
  radioRoot: {
    display: "none",
    backgroundColor: "#FFFFFF"
  },
  label: {
    textTransform: "none"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  formLabel: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    marginRight: "4vw"
  }
};

class Accomodations extends Component {
  state = {
    rewardMember: null,
    rewardDetails: ""
  };

  continue = event => {
    event.preventDefault();
    this.props.nextStep();
  };

  previous = event => {
    event.preventDefault();
    this.props.prevStep();
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  handleNextClick = () => {
    this.props.history.push("/plan-trip");
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <Button
              onClick={this.previous}
              style={{
                float: "left",
                background: "none",
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "23px",
                width: "60px",
                color: "#7eb8c4",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginBottom: "2vh",
                marginLeft: "10vw"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              <ArrowBack classes={{ root: classes.arrowRoot }} />
              Back
            </Button>
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Set Accomodation Preferences
            </Typography>
            </div>
            <div className="userForms">
              <h5 className="userInfoLabel">Where do you prefer to stay when you travel?</h5>

              <FormGroup row className="accomodationCheckBoxes">
                <div className="accomodationCheckBoxes" align="left" style={{ marginLeft: "36px" }}>
                  <FormControlLabel
                    classes={{ label: classes.formLabel }}
                    control={
                      <Checkbox
                        checked={values.homestay}
                        onChange={this.props.handleChangeCheckBox(
                          "homestay"
                        )}
                        value="homestay"
                        color="secondary"
                      />
                    }
                    label="Homestay (Airbnb, HomeAway, VRBO)"
                  />
                  <br></br>
                  <FormControlLabel
                    classes={{ label: classes.formLabel }}
                    control={
                      <Checkbox
                        checked={values.hotel}
                        onChange={this.props.handleChangeCheckBox("hotel")}
                        value="Hotel"
                      />
                    }
                    label="Hotel (Hilton, Marriott, Starwood, Intercontinental, Booking.com)"
                  />
                  <br></br>
                  <FormControlLabel
                    classes={{ label: classes.formLabel }}
                    control={
                      <Checkbox
                        checked={values.hostel}
                        onChange={this.props.handleChangeCheckBox("hostel")}
                        value="Hostel"
                        color="secondary"
                      />
                    }
                    label="Hostel (HostelHunter)"
                  />
                </div>
              </FormGroup>

              <h5 className="userInfoLabel">Are you part of any rewards programs?</h5>
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                className={classes.group}
                value={values.accomodationRewards}
                onChange={this.props.handleInputChangeFor("accomodationRewards")}
              >
                <FormControlLabel value="yes" classes={{ label: classes.formLabel }} style={{ margin: "auto" }} control={<Radio />} label="Yes" />
                <FormControlLabel value="no" classes={{ label: classes.formLabel }} style={{ margin: "auto" }} control={<Radio />} label="No" />
              </RadioGroup>

              <h5 className="userInfoLabel">If yes, please specify</h5>
              <input
                type="text"
                style={{ fontSize: "16px" }}
                className="infoInput"
                value={values.accomodationRewardDetails}
                onChange={this.props.handleInputChangeFor("accomodationRewardDetails")}
              />
            </div>
            <pre />
            <Button
              value="submit"
              type="submit"
              size="medium"
              onClick={this.continue}
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "25px"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              Next
                <ArrowForward classes={{ root: classes.arrowRoot }} />
            </Button>
          </div>
        </MuiThemeProvider>
      </div >
    );
  }
}

Accomodations.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Accomodations))
);

import React, { Component } from "react";
import { connect } from "react-redux";
import "./TravelPreferencesForm.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Budget from "./Budget/Budget";
import Accomodations from "./Accomodations/Accomodations";
import MobileStepper from "@material-ui/core/MobileStepper";
import FlightInfo from "../FlightInfo/FlightInfo";
import TransportationPreferences from "../TransportationPreferences/TransportationPreferences";
import ExperiencePreferences from "../ExperiencePreferences/ExperiencePreferences";
import Meals from "../Meals/Meals";

const styles = {
  mobileStepperOne: {
    background: "none",
    marginBottom: "232px",
    width: "140px",
    margin: "auto",
  },
  mobileStepperTwo: {
    background: "none",
    marginTop: "25px",
    marginBottom: "253px",
    width: "120px",
    margin: "auto",
  },
  dot: {
    height: "15px",
    width: "15px",
    backgroundColor: "#c8c9d1"
  },
  dotActive: {
    backgroundColor: "#f16b52"
  },
  dots: {
    margin: "auto",
  }
};

class TravelPreferencesForm extends Component {
  state = {
    step: 1,
    open: true,
    confirmationOpen: false,
    // budget info
    accomodations: "",
    flights: "",
    meals: "",
    public_transportation: "",
    activities: "",
    // accomodation info
    hotel: false,
    hostel: false,
    homestay: false,
    accomodationRewards: "",
    accomodationRewardDetails: "",
    // flight info
    airlinePreference: "",
    airlineRewards: "",
    ticketPreference: "",
    seatPreference: "",
    additionalFlightInfo: "",
    // transportation info
    walking: false,
    publicTransit: false,
    rideSharing: false,
    privateDrivers: false,
    // experience info
    tours: false,
    history: false,
    nightLife: false,
    adventure: false,
    // meal info
    dairyFree: false,
    vegetarian: false,
    glutenFree: false,
    vegan: false,
    peanutFree: false,
    keto: false,
    pescaterian: false,
    paleo: false
  };

  //   Proceeds to next Step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
    console.log(this.step)
  };

  //   Go back to previous Step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleSubmitClick = () => {
    this.props.dispatch({
      type: 'ADD_TRAVEL_PREFERENCES',
      payload: {
        id: this.props.user.id,
        // budget info
        accomodations: this.state.accomodations,
        flights: this.state.flights,
        meals: this.state.meals,
        public_transportation: this.state.public_transportation,
        activities: this.state.activities,
        // accomodation info
        hotel: this.state.hotel,
        hostel: this.state.hostel,
        homestay: this.state.homestay,
        accomodationRewards: this.state.accomodationRewards,
        accomodationRewardDetails: this.state.accomodationRewardDetails,
        // flight info
        airlinePreference: this.state.airlinePreference,
        airlineRewards: this.state.airlineRewards,
        ticketPreference: this.state.ticketPreference,
        seatPreference: this.state.seatPreference,
        additionalFlightInfo: this.state.additionalFlightInfo,
        // transportation info
        walking: this.state.walking,
        publicTransit: this.state.publicTransit,
        rideSharing: this.state.rideSharing,
        privateDrivers: this.state.privateDrivers,
        // experience info
        tours: this.state.tours,
        history: this.state.history,
        nightLife: this.state.nightLife,
        adventure: this.state.adventure,
        // meal info
        dairyFree: this.state.dairyFree,
        vegetarian: this.state.vegetarian,
        glutenFree: this.state.glutenFree,
        vegan: this.state.vegan,
        peanutFree: this.state.peanutFree,
        keto: this.state.keto,
        pescaterian: this.state.pescaterian,
        paleo: this.state.paleo
      }
    });
    this.props.history.push("/invite-travelers")
  }

  handleChangeCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
    console.log(this.state);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.currentTarget.value });
    console.log(this.state);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmationClose = () => {
    this.setState({ confirmationOpen: false });
  };

  handleConfirmationOpen = () => {
    this.setState({ confirmationOpen: true });
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
    console.log(this.state);

  };

  render() {
    const { classes } = this.props;
    const { step } = this.state;
    const {
      // budget info
      accomodations,
      flights,
      meals,
      public_transportation,
      activities,
      // accomodation info
      hotel,
      hostel,
      vacationRental,
      accomodationRewards,
      accomodationRewardsDetails,
      // flight info
      airlinePreference,
      airlineRewards,
      ticketPreference,
      seatPreference,
      additionalFlightInfo,
      // transportation info
      walking,
      publicTransit,
      rideSharing,
      privateDrivers,
      // experience info
      tours,
      history,
      nightLife,
      adventure,
      // meal info
      dairyFree,
      vegetarian,
      glutenFree,
      vegan,
      peanutFree,
      keto,
      pescaterian,
      paleo
    } = this.state;

    const values = {
      // budget info
      accomodations,
      flights,
      meals,
      public_transportation,
      activities,
      // accomodation info
      hotel,
      hostel,
      vacationRental,
      accomodationRewards,
      accomodationRewardsDetails,
      // flight info
      airlinePreference,
      airlineRewards,
      ticketPreference,
      seatPreference,
      additionalFlightInfo,
      // transportation info
      walking,
      publicTransit,
      rideSharing,
      privateDrivers,
      // experience info
      tours,
      history,
      nightLife,
      adventure,
      // meal info
      dairyFree,
      vegetarian,
      glutenFree,
      vegan,
      peanutFree,
      keto,
      pescaterian,
      paleo
    };

    switch (step) {
      case 1:
        return (
          <div>
            <Budget
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
              open={this.state.open}
              handleClose={this.handleClose}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={this.step}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );
      case 2:
        return (
          <div>
            <Accomodations
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChangeCheckBox={this.handleChangeCheckBox}
              values={values}
              accomodations={this.state.accomodations}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
              handleInputChangeFor={this.handleInputChangeFor}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={1}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );
      case 3:
        return (
          <div>
            <FlightInfo
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChangeCheckBox={this.handleChangeCheckBox}
              values={values}
              accomodations={this.state.accomodations}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
              handleInputChangeFor={this.handleInputChangeFor}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={2}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );
      case 4:
        return (
          <div>
            <TransportationPreferences
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChangeCheckBox={this.handleChangeCheckBox}
              values={values}
              accomodations={this.state.accomodations}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
              handleInputChangeFor={this.handleInputChangeFor}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={3}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );
      case 5:
        return (
          <div>
            <ExperiencePreferences
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChangeCheckBox={this.handleChangeCheckBox}
              values={values}
              accomodations={this.state.accomodations}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
              handleInputChangeFor={this.handleInputChangeFor}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={4}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );

      case 6:
        return (
          <div>
            <Meals
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChangeCheckBox={this.handleChangeCheckBox}
              values={values}
              accomodations={this.state.accomodations}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
              handleInputChangeFor={this.handleInputChangeFor}
            />
            <MobileStepper
              steps={6}
              position="static"
              activeStep={5}
              className={classes.mobileStepperTwo}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );

      default:
        break;
    }
  }
}

TravelPreferencesForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(
  withStyles(styles)(TravelPreferencesForm)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import "./ContactInfo.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../../Header/Header";
import Confirmation from "../../Confirmation/Confirmation";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";

const styles = {
  root: {
    flexGrow: 1,
    fontSize: "fontSizeSmall"
  },
  grow: {
    flexGrow: 1
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    height: "46px",
    width: "100%",
    maxWidth: "570px",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto"
  },
  label: {
    textTransform: "none"
  },
  formControl: {
    backgroundColor: "#f4f4f6",
    color: "#CBCCD4",
    width: "75px"
  },
  select: {
    color: "#252947",
    width: "80px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    textAlign: "center",
    margin: "0",
    padding: "0",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "normal",
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  menuRoot: {
    fontFamily: "Poppins, sans-serif",
  }
};

const selectStyle = {
  color: "#CBCCD4"
};

const title = "Does this look right?";

class ContactInfo extends Component {
  previous = event => {
    event.preventDefault();
    this.props.prevStep();
  };

  handleNextClick = () => {
    this.props.history.push("/traveler-preferences");
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <Button
              value="submit"
              type="submit"
              size="medium"
              onClick={this.previous}
              style={{
                float: "left",
                background: "none",
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "23px",
                width: "60px",
                color: "#7eb8c4",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginBottom: "2vh",
                marginLeft: "146px"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              <ArrowBack classes={{ root: classes.arrowRoot }} />
              Back
            </Button>
            <div className="infoDiv">
              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Contact Info
            </Typography>
              <p className="pageCaption">
                Please enter all relevant contact information.
            </p>
            </div>

            <form
              autoComplete="off"
              id="contactInfoForm"
              className="userForms"
              onSubmit={this.props.handleConfirmationOpen}
            >
              <div className="inputDiv">
                <h5 className="userInfoLabel">Primary Phone Number</h5>
                <input
                  required
                  type="number"
                  style={{ fontSize: "16px" }}
                  className="infoInput"
                  value={values.phoneNumber}
                  onChange={this.props.handleInputChangeFor("phoneNumber")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Address</h5>
                <input
                  required
                  placeholder="Address Line One"
                  type="text"
                  style={{ fontSize: "16px" }}
                  className="infoInput"
                  value={values.addressLineOne}
                  onChange={this.props.handleInputChangeFor("addressLineOne")}
                />
                <div className="addressDiv">
                  <input
                    placeholder="Address Line Two"
                    type="text"
                    style={{ fontSize: "16px" }}
                    className="infoInput"
                    value={values.addressLineTwo}
                    onChange={this.props.handleInputChangeFor("addressLineTwo")}
                  />
                </div>
                <div className="addressDiv" align="left">
                  <input
                    required
                    placeholder="City"
                    id="cityInput"
                    type="text"
                    style={{ fontSize: "16px", marginBottom: "1.8vh" }}
                    className="infoInput"
                    value={values.city}
                    onChange={this.props.handleInputChangeFor("city")}
                  />
                  <FormControl id="stateSelect" className={classes.formControl}>
                    <Select
                      className="profileSelect"
                      styles={selectStyle}
                      displayEmpty
                      classes={{ select: classes.select }}
                      disableUnderline
                      value={values.state}
                      onChange={this.props.handleInputChangeFor("state")}
                    >
                      <MenuItem classes={{ root: classes.menuRoot }} value="" disabled>State</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"AL"}>AL</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"AK"}>AK</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"AR"}>AR</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"CA"}>CA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"CO"}>CO</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"CT"}>CT</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"DE"}>DE</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"FL"}>FL</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"GA"}>GA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"HI"}>HI</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ID"}>ID</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IL"}>IL</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IN"}>IN</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IA"}>IA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"KS"}>KS</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"KY"}>KY</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"LA"}>LA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ME"}>ME</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MD"}>MD</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MA"}>MA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MI"}>MI</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MN"}>MN</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MS"}>MS</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MO"}>MO</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MT"}>MT</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ME"}>ME</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NE"}>NE</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NV"}>NV</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NH"}>NH</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NJ"}>NJ</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NJ"}>NJ</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NM"}>NM</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NY"}>NY</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NC"}>NC</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ND"}>ND</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"OH"}>OH</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"OK"}>OK</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"OR"}>OR</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"PA"}>PA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"RI"}>RI</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"SC"}>SC</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"SD"}>SD</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"TN"}>TN</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"TX"}>TX</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"UT"}>UT</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"VT"}>VT</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"VA"}>VA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"WA"}>WA</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"WV"}>WV</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"WI"}>WI</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"WY"}>WY</MenuItem>
                    </Select>
                  </FormControl>
                  <input
                    required
                    placeholder="Zip Code"
                    id="zipCodeInput"
                    type="number"
                    style={{ fontSize: "16px" }}
                    className="infoInput"
                    value={values.zipCode}
                    onChange={this.props.handleInputChangeFor("zipCode")}
                  />
                </div>
              </div>
              <Button
                value="submit"
                type="submit"
                size="medium"
                onClick={this.props.handleConfirmationOpen}
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: "200px",
                  marginBottom: "151px"
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Next
                <ArrowForward classes={{ root: classes.arrowRoot }} />
              </Button>
            </form>
            <Confirmation
              title={title}
              confirmationOpen={this.props.confirmationOpen}
              handleConfirmationClose={this.props.handleConfirmationClose}
              values={this.props.values}
              handleNextClick={this.handleNextClick}
              handleSubmitClick={this.props.handleSubmitClick}
            />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

ContactInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ContactInfo))
);
import React from "react";
import "./Confirmation.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  h4: {
    marginBottom: "4.4%",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "3vh",
    lineHeight: "50px",
    textAlign: "center",
    margin: "0",
    marginTop: "4vmin"
  },
  paper: {
    height: "69.4vh",
    width: "75vw"
  },
  label: {
    textTransform: "none"
  },
  textRoot: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#252947",
    width: "74%",
    margin: "0"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  }
});


class Confirmation extends React.Component {
  handleNextClick = nextURL => event => {
    event.preventDefault()
    this.props.history.push(nextURL);
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    return (
      <div className="dialog">
        <Dialog
          open={this.props.confirmationOpen}
          onClose={this.props.handleConfirmationClose}
          classes={{ paper: classes.paper }}
        >
          <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
            Does this look right?
          </Typography>
          <Typography classes={{ root: classes.textRoot }}>
            <div className="confirmationColumn">
              <div className="leftConfirmationColumn" align="left">
                <h5 className="confirmationInfoLabel">First Name</h5>
                <p className="confirmationText">{values.firstName}</p>
                <h5 className="confirmationInfoLabel">Last Name</h5>
                <p className="confirmationText">{values.lastName}</p>
                <h5 className="confirmationInfoLabel">Gender</h5>
                <p className="confirmationText">{values.gender}</p>
                <h5 className="confirmationInfoLabel">Address</h5>
                <p className="confirmationText">
                  {values.addressLineOne}
                  &nbsp;
                  {values.city}
                  &nbsp;
                  {values.state}
                  &nbsp;
                  {values.zipCode}
                </p>
              </div>
              <div className="rightConfirmationColumn" align="left">
                <h5 className="confirmationInfoLabel">Middle Name</h5>
                <p className="confirmationText">{values.middleName.length < 1 ? ("N/A") : (values.middleName)}</p>
                <h5 className="confirmationInfoLabel">Date of Birth</h5>
                <p className="confirmationText">
                {values.birthMonth.length < 1 ? ("N/A") : (`${values.birthMonth}/${values.birthDay}/${values.birthYear}`)}
                  {/* {values.birthMonth}/{values.birthDay}/{values.birthYear} */}
                </p>
                <h5 className="confirmationInfoLabel">Primary Number</h5>
                <p className="confirmationText">{values.phoneNumber}</p>
              </div>
            </div>
          </Typography>
          <div className="confirmationButtons">
            <Button
              onClick={this.props.handleConfirmationClose}
              size="medium"
              variant="outlined"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-block",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#7EB8C4",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginBottom: "2vh",
              }}
              classes={{ label: classes.label }}
              color="#FFFFFF"
            >
              <ArrowBack classes={{ root: classes.arrowRoot }} />
              No, go back.
            </Button>
            <Button
              onClick={this.props.handleSubmitClick}
              size="medium"
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-block",
                alignItems: "center",
                margin: "0",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginLeft: "4%"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              Yeah, let's continue.
              <ArrowForward classes={{ root: classes.arrowRoot }} />
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Confirmation));
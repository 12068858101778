import React, { Component } from "react";
import { connect } from "react-redux";
import "./TravelerInfo.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../../Header/Header";
import Dialog from "../../Dialog/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import MediaQuery from "react-responsive";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none"
  },
  formControlDate: {
    align: "left",
    backgroundColor: "#f4f4f6",
    color: "#CBCCD4"
  },
  select: {
    color: "#252947",
    width: "70px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    textAlign: "center",
    margin: "0",
    padding: "0",
    fontWeight: "normal",
    fontFamily: "Poppins, sans-serif"
  },
  fontSizeSmall: {
    height: "2px",
    width: "2px"
  },
  yearSelect: {
    paddingRight: "60px",
    color: "#252947",
    width: "60px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    textAlign: "center",
    margin: "0",
    padding: "0",
    fontWeight: "normal",
  },
  desktopYearSelect: {
    paddingRight: "80px",
    color: "#252947",
    width: "100px",
    paddingTop: "18.25px",
    paddingBottom: "18.25px",
    textAlign: "center",
    margin: "0",
    padding: "0",
    fontWeight: "normal",
  },
  genderSelect: {
    color: "#252947",
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    margin: "0",
    padding: "0",
    fontFamily: "Poppins, sans-serif",
    paddingLeft: "20px"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  disabled: {
    color: "#CBCCD4"
  },
  menuRoot: {
    fontFamily: "Poppins, sans-serif",
  }
};

const selectStyle = {
  color: "#252947"
};

const title = "Traveler Info";

const contentText = `You are at the center of the asugo experience. Let’s start by setting
up your traveler profile so that we can make recommendations that align with your travel preferences
and behaviors.`;

const buttonText = `Sweet, let’s get started.`;

class TravelerInfo extends Component {
  continue = event => {
    event.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <Dialog
              title={title}
              open={this.props.open}
              handleClose={this.props.handleClose}
              buttonText={buttonText}
              contentText={contentText}
            />
            <div className="infoDiv">
              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }} className="f1">
                Traveler Info
              </Typography>

              <p className="pageCaption">
                Your traveler profile information will be used to assist with the
                booking process, so we have a few questions about your travel
                experiences and how you typically travel.
              </p>
            </div>

            <form
              autoComplete="off"
              id="travelerInfoForm"
              className="userForms"
            >
              <div className="inputDiv">
                <h5 className="userInfoLabel" style={{ marginTop: "20px" }}>First Name</h5>
                <input
                  required
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={values.firstName}
                  onChange={this.props.handleInputChangeFor("firstName")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Middle Name <span className="captionContext">(optional)</span></h5>
                <input
                  required
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={values.middleName}
                  onChange={this.props.handleInputChangeFor("middleName")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Last Name</h5>
                <input
                  required
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={values.lastName}
                  onChange={this.props.handleInputChangeFor("lastName")}
                />
              </div>
              <div className="inputDiv">
                <div align="left">
                  <h5 className="userInfoLabel">Birthday</h5>
                  <FormControl className={classes.formControlDate}>
                    <Select
                      value={values.birthMonth}
                      onChange={this.props.handleInputChangeFor("birthMonth")}
                      styles={selectStyle}
                      displayEmpty
                      classes={{
                        select: classes.select,
                        input: classes.input,
                        disabled: classes.disabled
                      }}
                      disableUnderline
                    >
                      <MenuItem className="disabledSelect" disabled value="">
                        MM
                      </MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={1}>01</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={2}>02</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={3}>03</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={4}>04</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={5}>05</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={6}>06</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={7}>07</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={8}>08</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={9}>09</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={10}>10</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={11}>11</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={12}>12</MenuItem>
                    </Select>
                  </FormControl>
                  {/* Mobile */}
                  <MediaQuery query="(max-width: 1024px)">
                    &nbsp;&nbsp;
                  </MediaQuery>
                  {/* Desktop */}
                  <MediaQuery query="(min-width: 1024.1px)">
                    &emsp;
                  </MediaQuery>
                  <FormControl className={classes.formControlDate}>
                    <Select
                      value={values.birthDay}
                      onChange={this.props.handleInputChangeFor("birthDay")}
                      styles={selectStyle}
                      displayEmpty
                      classes={{ select: classes.select }}
                      disableUnderline
                    >
                      <MenuItem disabled value="">
                        DD
                      </MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={1}>01</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={2}>02</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={3}>03</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={4}>04</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={5}>05</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={6}>06</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={7}>07</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={8}>08</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={9}>09</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={10}>10</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={11}>11</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={12}>12</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={13}>13</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={14}>14</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={15}>15</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={16}>16</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={17}>17</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={18}>18</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={19}>19</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={20}>20</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={21}>21</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={22}>22</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={23}>23</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={24}>24</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={25}>25</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={26}>26</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={27}>27</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={28}>28</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={29}>29</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={30}>30</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={31}>31</MenuItem>
                    </Select>
                  </FormControl>
                  {/* Mobile select spacing */}
                  <MediaQuery query="(max-width: 1024px)">
                    &nbsp;&nbsp;
                  </MediaQuery>
                  {/* Desktop select spacing */}
                  <MediaQuery query="(min-width: 1024.1px)">
                    &emsp;
                  </MediaQuery>
                  {/* Mobile for Year Select */}
                  <MediaQuery query="(max-width: 1024px)">
                    <FormControl
                      className={classes.formControlDate}
                    >
                      <Select
                        value={values.birthYear}
                        onChange={this.props.handleInputChangeFor("birthYear")}
                        displayEmpty
                        classes={{ select: classes.yearSelect }}
                        disableUnderline
                      >
                        <MenuItem disabled value="">
                          YYYY
                      </MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1900}>1900</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1901}>1901</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1902}>1902</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1903}>1903</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1904}>1904</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1905}>1905</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1906}>1906</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1907}>1907</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1908}>1908</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1909}>1909</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1910}>1910</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1911}>1911</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1912}>1912</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1913}>1913</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1914}>1914</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1915}>1915</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1916}>1916</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1917}>1917</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1918}>1918</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1919}>1919</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1920}>1920</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1921}>1921</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1922}>1922</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1923}>1923</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1924}>1924</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1925}>1925</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1926}>1926</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1927}>1927</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1928}>1928</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1929}>1929</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1930}>1930</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1931}>1931</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1932}>1932</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1933}>1933</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1934}>1934</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1935}>1935</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1936}>1936</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1937}>1937</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1938}>1938</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1939}>1939</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1940}>1940</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1941}>1941</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1942}>1942</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1943}>1943</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1944}>1944</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1945}>1945</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1946}>1946</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1947}>1947</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1948}>1948</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1949}>1949</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1950}>1950</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1951}>1951</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1952}>1952</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1953}>1953</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1954}>1954</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1955}>1955</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1956}>1956</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1957}>1957</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1958}>1958</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1959}>1959</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1960}>1960</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1961}>1961</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1962}>1962</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1963}>1963</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1964}>1964</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1965}>1965</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1966}>1966</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1967}>1967</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1968}>1968</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1969}>1969</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1970}>1970</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1971}>1971</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1972}>1972</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1973}>1973</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1974}>1974</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1975}>1975</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1976}>1976</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1977}>1977</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1978}>1978</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1979}>1979</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1980}>1980</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1981}>1981</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1982}>1982</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1983}>1983</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1984}>1984</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1985}>1985</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1986}>1986</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1987}>1987</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1988}>1988</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1989}>1989</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1990}>1990</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1991}>1991</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1992}>1992</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1993}>1993</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1994}>1994</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1995}>1995</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1996}>1996</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1997}>1997</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1998}>1998</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1999}>1999</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2000}>2000</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2001}>2001</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2002}>2002</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2003}>2003</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2004}>2004</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2005}>2005</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2006}>2006</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2007}>2007</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2008}>2008</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2009}>2009</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2010}>2010</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2011}>2011</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2012}>2012</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2013}>2013</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2014}>2014</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2015}>2015</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2016}>2016</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2017}>2017</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2018}>2018</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2019}>2019</MenuItem>
                      </Select>
                    </FormControl>
                  </MediaQuery>
                  {/* Desktop for Year Select */}
                  <MediaQuery query="(min-width: 1024.1px)">
                    <FormControl
                      className={classes.formControlDate}
                    >
                      <Select
                        value={values.birthYear}
                        onChange={this.props.handleInputChangeFor("birthYear")}
                        displayEmpty
                        classes={{ select: classes.desktopYearSelect }}
                        disableUnderline
                      >
                        <MenuItem disabled value="">
                          YYYY
                      </MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1900}>1900</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1901}>1901</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1902}>1902</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1903}>1903</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1904}>1904</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1905}>1905</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1906}>1906</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1907}>1907</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1908}>1908</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1909}>1909</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1910}>1910</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1911}>1911</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1912}>1912</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1913}>1913</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1914}>1914</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1915}>1915</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1916}>1916</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1917}>1917</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1918}>1918</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1919}>1919</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1920}>1920</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1921}>1921</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1922}>1922</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1923}>1923</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1924}>1924</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1925}>1925</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1926}>1926</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1927}>1927</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1928}>1928</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1929}>1929</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1930}>1930</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1931}>1931</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1932}>1932</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1933}>1933</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1934}>1934</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1935}>1935</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1936}>1936</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1937}>1937</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1938}>1938</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1939}>1939</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1940}>1940</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1941}>1941</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1942}>1942</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1943}>1943</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1944}>1944</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1945}>1945</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1946}>1946</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1947}>1947</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1948}>1948</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1949}>1949</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1950}>1950</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1951}>1951</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1952}>1952</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1953}>1953</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1954}>1954</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1955}>1955</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1956}>1956</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1957}>1957</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1958}>1958</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1959}>1959</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1960}>1960</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1961}>1961</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1962}>1962</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1963}>1963</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1964}>1964</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1965}>1965</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1966}>1966</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1967}>1967</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1968}>1968</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1969}>1969</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1970}>1970</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1971}>1971</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1972}>1972</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1973}>1973</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1974}>1974</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1975}>1975</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1976}>1976</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1977}>1977</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1978}>1978</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1979}>1979</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1980}>1980</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1981}>1981</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1982}>1982</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1983}>1983</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1984}>1984</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1985}>1985</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1986}>1986</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1987}>1987</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1988}>1988</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1989}>1989</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1990}>1990</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1991}>1991</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1992}>1992</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1993}>1993</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1994}>1994</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1995}>1995</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1996}>1996</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1997}>1997</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1998}>1998</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={1999}>1999</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2000}>2000</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2001}>2001</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2002}>2002</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2003}>2003</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2004}>2004</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2005}>2005</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2006}>2006</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2007}>2007</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2008}>2008</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2009}>2009</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2010}>2010</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2011}>2011</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2012}>2012</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2013}>2013</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2014}>2014</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2015}>2015</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2016}>2016</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2017}>2017</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2018}>2018</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={2019}>2019</MenuItem>
                      </Select>
                    </FormControl>
                  </MediaQuery>
                </div>
              </div>

              <div className="genderDiv">
                <h5 className="userInfoLabel">Gender</h5>
                <FormControl className={classes.formControl}>
                  <Select
                    value={values.gender}
                    onChange={this.props.handleInputChangeFor("gender")}
                    inputProps={{
                      name: "gender",
                      id: "gender"
                    }}
                    className="profileSelect"
                    classes={{ select: classes.genderSelect }}
                    displayEmpty
                    disableUnderline
                    width="100%"
                  >
                    <MenuItem classes={{ root: classes.menuRoot }} value="" disabled>
                      Select an option
                    </MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"Male"}>Male</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"Female"}>Female</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <br />
              <pre />
              <div className="nextButton">
                <Button
                  size="medium"
                  onClick={this.continue}
                  variant="contained"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    margin: "auto",
                    borderRadius: "25px",
                    height: "50px",
                    width: "168px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "16px",
                    textAlign: "center",
                  }}
                  classes={{ label: classes.label }}
                  color="secondary"
                >
                  Next
                  <ArrowForward classes={{ root: classes.arrowRoot }} />
                </Button>
              </div>
            </form>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

TravelerInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(TravelerInfo));

import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Register from "../Register/Register";
import AddTraveler from "../AddTraveler/AddTraveler";
import AddUser from "../AddUser/AddUser";
import InviteTraveler from "../InviteTraveler/InviteTraveler";
import Location from "../Location/Location";
import PlanTrip from "../PlanTrip/PlanTrip";
import Travelers from "../Travelers/Travelers";
import TravelProfileForm from "../TravelProfileForm/TravelProfileForm";
import Success from "../Success/Success";
import Welcome from "../Welcome/Welcome";
import FlightInfo from "../FlightInfo/FlightInfo";
import TravelPreferencesForm from "../TravelPreferencesForm/TravelPreferencesForm";
import ChooseDates from "../ChooseDates/ChooseDates";
import TransportationPreferences from "../TransportationPreferences/TransportationPreferences";
import ExperiencePreferences from "../ExperiencePreferences/ExperiencePreferences";
import Meals from "../Meals/Meals";

// For future release
// import CreateTraveler from "../CreateTraveler/CreateTraveler";
// import Dashboard from "../Dashboard/Dashboard";
// import BucketList from "../BucketList/BucketList";

class App extends Component {
  componentDidMount() {
    this.props.dispatch({ type: "FETCH_USER" });
    this.props.dispatch({ type: 'FETCH_USERS' })
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            {/* <Header /> */}
            <Switch>
              {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
              <Redirect exact from="/" to="/welcome" />
              {/* For protected routes, the view could show one of several things on the same route.
                Visiting localhost:3000/home will show the UserDashboard if the user is logged in.
                If the user is not logged in, the ProtectedRoute will show the 'Login' or 'Register' page.
                Even though it seems like they are different pages, the user is always on localhost:3000/home */}
              <ProtectedRoute path="/add-traveler" exact component={AddTraveler} />
              <ProtectedRoute path="/add-user" exact component={AddUser} />
              <ProtectedRoute path="/dates" exact component={ChooseDates} />
              {/* <ProtectedRoute path="/bucket-list" exact component={BucketList} /> */}
              <ProtectedRoute path="/traveler-preferences" exact component={TravelPreferencesForm} />
              {/* <ProtectedRoute path="/create-traveler" exact component={CreateTraveler} /> */}
              {/* <ProtectedRoute path="/dashboard" exact component={Dashboard} /> */}
              <ProtectedRoute path="/experiences" exact component={ExperiencePreferences} />
              <ProtectedRoute path="/flight-info" exact component={FlightInfo} />
              <ProtectedRoute path="/invite-travelers" exact component={InviteTraveler} />
              <ProtectedRoute
                path="/location"
                exact
                component={Location}
              />
              <ProtectedRoute path="/meals" exact component={Meals} />
              <ProtectedRoute path="/plan-trip" exact component={PlanTrip} />
              <ProtectedRoute path="/transportation" exact component={TransportationPreferences} />
              <ProtectedRoute path="/travelers" exact component={Travelers} />
              <ProtectedRoute path="/traveler-profile" exact component={TravelProfileForm} />
              <ProtectedRoute path="/success" exact component={Success} />
              <ProtectedRoute path="/welcome" exact component={Welcome} />

              {/* Unprotected Routes */}
              <Route path="/register" exact component={Register} />
              {/* If none of the other routes matched, we will show a 404. */}
              <Route render={() => <h1>404</h1>} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default connect()(App);

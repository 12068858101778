import React, { Component } from "react";
import { connect } from "react-redux";
import "./Header.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router-dom";
import LogOutButton from "../LogOutButton/LogOutButton";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

const menuIconStyle = {
  padding: "0"
};

class Navbar extends Component {
  state = {
    left: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  handleNavOptionClick = navButton => event => {
    event.preventDefault();
    if (navButton === "Traveler Profile") {
      this.props.history.push("/traveler-profile");
    } else if (navButton === "Traveler Preferences") {
      this.props.history.push("/traveler-preferences");
    } else if (navButton === "Register") {
      this.props.history.push("/");
    } else if (navButton === "Plan Trip") {
      this.props.history.push("/plan-trip");
    } else if (navButton === "Location") {
      this.props.history.push("/location");
    } else if (navButton === "Travelers") {
      this.props.history.push("/travelers");
    } else if (navButton === "Add Traveler") {
      this.props.history.push("/add-traveler");
    } else if (navButton === "Invite Traveler") {
      this.props.history.push("/invite-traveler");
    } else if (navButton === "Add Asugo User") {
      this.props.history.push("/add-user");
    } else if (navButton === "Dates") {
      this.props.history.push("/dates");
    }
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#252947"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    const navList = (
      <div className={classes.list}>
        <List>
          {[
            "Traveler Profile",
            "Traveler Preferences",
            "Plan Trip",
            "Location",
            "Travelers",
            "Add Traveler",
            "Invite Traveler",
            "Add Asugo User",
            "Dates"
          ].map((text, index) => (
            <ListItem
              button
              onClick={this.handleNavOptionClick(text)}
              key={text}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <AppBar position="fixed" color="primary">
            <Toolbar>
              <div className="navbar">
                {/* Drawer Open Icon and Toggle */}
                {this.props.user.id && (
                  <div className="menuIcon">
                    <IconButton
                      onClick={this.toggleDrawer("left", true)}
                      color="inherit"
                      aria-label="Menu"
                      style={menuIconStyle}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                )}

                <a href="/" className="logo">
                  <img
                    src={require("./asugo_travel_inverse_logo.png")}
                    height="24vh"
                    width="120px"
                    alt="logo"
                    className="logo"
                  />
                </a>
              </div>
              {/* Login/Register/Logout */}
              <div className="nav-right">
                {/* Show the link to the info page and the logout button if the user is logged in */}
                {this.props.user.id && (
                  <>
                    <LogOutButton className="nav-link" />
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
          {/* Drawer */}
          {this.props.user.id && (
            <>
              <Drawer
                open={this.state.left}
                onClose={this.toggleDrawer("left", false)}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={this.toggleDrawer("left", false)}
                  onKeyDown={this.toggleDrawer("left", false)}
                >
                  {navList}
                </div>
              </Drawer>
            </>
          )}
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

Navbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Navbar)));

const travelProfile = (state = {}, action) => {
    switch (action.type) {
      case 'SET_TRAVEL_PROFILE':
        return action.payload;
      case 'CLEAR_TRAVEL_PROFILE':
        return {};
      default:
        return state;
    }
  };
  
  // travelProfile will be on the redux state at:
  // state.travelProfile
  export default travelProfile;
  
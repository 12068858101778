import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  h4: {
    marginBottom: "4.4%",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "4.6vmin",
    lineHeight: "50px",
    textAlign: "center",
    margin: "0",
    marginTop: "3vmin"
  },
  paper: {
    width: "75vw"
  },
  label: {
    textTransform: "none"
  },
  rootDialogContent: {
    flex: "none",
    paddingBottom: "0",
    width: "74%",
    margin: "auto",
    marginTop: "8.8%"
  },
  rootDialogActions: {
    marginTop: "8vmin",
    paddingBottom: "6vmin"
  },
  rootDialogTitle: {
    width: "74%",
    margin: "auto",
    padding: "0",
    height: "4.49vh",
    marginTop: "8%,"
  },
  textRoot: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#252947",
    width: '74%',
    margin: 'auto'
  }
});

class DialogBox extends React.Component {
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="dialog">
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          classes={{ paper: classes.paper }}
        >
          <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
            {this.props.title}
          </Typography>
          <Typography classes={{ root: classes.textRoot }}>{this.props.contentText}</Typography>
            <Button
              onClick={this.props.handleClose}
              size="medium"
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "3vh",
                marginBottom: "3vh"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              {this.props.buttonText}
            </Button>
        </Dialog>
      </div>
    );
  }
}

DialogBox.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DialogBox);
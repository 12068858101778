import React, { Component } from "react";
import { connect } from "react-redux";
import "./FlightInfo.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: '100%',
    align: "left",
    backgroundColor: "#f4f4f6",
    color: "#CBCCD4"
  },
  textField: {
    width: '100%',
    textAlign: 'center'
  },
  label: {
    textTransform: "none"
  },
  select: {
    color: "#252947",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    textAlign: "left",
    margin: "0",
    padding: "0",
    fontWeight: "normal"
  },
  menuRoot: {
    fontFamily: "Poppins, sans-serif",
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
};

const selectStyle = {
  color: "#252947",
  textAlign: "left"
};


class FlightInfo extends Component {
  state = {
    countryOfPassport: '',
    homeAirport: '',
    ticketPreferences: '',
    seatPreference: '',
  };

  handleCompleteClick = () => {
    this.props.dispatch({
      type: "ADD_TRAVEL_PROFILE",
      payload: {
        countryOfPassport: this.state.countryOfPassport,
        homeAirport: this.state.homeAirport,
        id: this.props.user.id
      }
    })
    this.props.history.push("/budget");
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  previous = event => {
    event.preventDefault();
    this.props.prevStep();
  };

  continue = event => {
    event.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values } = this.props;
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <Button
              onClick={this.previous}
              style={{
                float: "left",
                background: "none",
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "23px",
                width: "60px",
                color: "#7eb8c4",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginBottom: "2vh",
                marginLeft: "10vw"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              <ArrowBack classes={{ root: classes.arrowRoot }} />
              Back
            </Button>
            <div className="infoDiv">
              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Set Flight Preferences
              </Typography>
            </div>
            <form autoComplete="off" className="userForms">
              <h5 className="userInfoLabel">Which type of airline do you prefer?</h5>
              <input
                type="text"
                style={{ fontSize: "16px" }}
                className="infoInput"
                value={values.airlinePreference}
                onChange={this.props.handleInputChangeFor("airlinePreference")}
              />
              <br />
              <h5 className="userInfoLabel">Are you part of any rewards programs?</h5>
              <input
                type="text"
                style={{ fontSize: "16px" }}
                className="infoInput"
                value={values.airlineRewards}
                onChange={this.props.handleInputChangeFor("airlineRewards")}
              />
              <h5 className="userInfoLabel">Do you have a ticket preferences?</h5>
              <FormControl className={classes.formControl}>
                <Select
                  value={values.ticketPreference}
                  onChange={this.props.handleInputChangeFor("ticketPreference")}
                  styles={selectStyle}
                  displayEmpty
                  classes={{
                    select: classes.select,
                    input: classes.input,
                    disabled: classes.disabled
                  }}
                  disableUnderline
                >
                  <MenuItem className="disabledSelect" disabled value="">
                    Select an option
                      </MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="First Class">First Class</MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="Business Class">Business Class</MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="Economy">Economy</MenuItem>
                </Select>
              </FormControl>
              <br></br>
              <h5 className="userInfoLabel">Do you have a seat preference?</h5>
              <FormControl className={classes.formControl}>
                <Select
                  value={values.seatPreference}
                  onChange={this.props.handleInputChangeFor("seatPreference")}
                  styles={selectStyle}
                  displayEmpty
                  classes={{
                    select: classes.select,
                    input: classes.input,
                    disabled: classes.disabled
                  }}
                  disableUnderline
                >
                  <MenuItem className="disabledSelect" disabled value="">
                    Select an option
                      </MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="Aisle">Aisle</MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="Window">Window</MenuItem>
                  <MenuItem classes={{ root: classes.menuRoot }} value="Exit Row">Exit Row</MenuItem>
                  {/* Is other necessary? */}
                  {/* <MenuItem classes={{ root: classes.menuRoot }} value="Other">Other</MenuItem> */}
                </Select>
              </FormControl>
              <br></br>
              <h5 className="userInfoLabel">Anything else we should know about your flight preference?</h5>
              <input
                type="text"
                style={{ fontSize: "16px" }}
                className="infoInput"
                value={values.additionalFlightInfo}
                onChange={this.props.handleInputChangeFor("additionalFlightInfo")}
              />
              <br></br>
              <pre />
              <Button
                value="submit"
                type="submit"
                size="medium"
                onClick={this.continue}
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "25px"
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Next
                <ArrowForward classes={{ root: classes.arrowRoot }} />
              </Button>
            </form>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

FlightInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(FlightInfo));
import React, { Component } from "react";
import { connect } from "react-redux";
import "./Location.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: 'auto',
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none",
  },
  locationSelect: {
    color: "#252947",
    width: '100%',
    paddingTop: '15.5px',
    paddingBottom: '15.5px',
    margin: '0',
    padding: '0',
    paddingLeft: "12px",
    fontFamily: "Poppins",
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  menuRoot: {
    fontFamily: "Poppins, sans-serif",
  }
};

class Location extends Component {
  componentDidMount() {
    console.log(this.props.currentTrip)
    this.props.dispatch({
      type: "GET_TRIP_BY_ID", payload: {
        planner_id: this.props.user.id,
        tripName: this.props.currentTrip.tripName,
        tripType: this.props.currentTrip.tripType
      }
    });
  }
  state = {
    tripDestination: "",
    state: ""
  };

  previous = () => {
    this.props.history.push("/plan-trip")
  }

  handleNextClick = () => {
    this.props.history.push("/dates");
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
    console.log(this.state);

  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        {!this.props.currentTrip.tripName.length ? <p>loading...</p> :
          <MuiThemeProvider theme={theme}>
            <Header />
            <div className="pageHeader">
              <Button
                value="submit"
                type="submit"
                size="medium"
                onClick={this.previous}
                style={{
                  float: "left",
                  background: "none",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "23px",
                  width: "60px",
                  color: "#7eb8c4",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginBottom: "2vh",
                  marginLeft: "146px"
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                <ArrowBack classes={{ root: classes.arrowRoot }} />
                Back
            </Button>
              <div className="infoDiv">

                <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                  Location
                  </Typography>
                <p className="pageCaption">
                  Where are you planning to go?
                  </p>
              </div>
              <form autoComplete="off" className="userForms">
                <div className="locationDiv">
                  <h5 className="userInfoLabel">Trip Destination</h5>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={this.state.tripDestination}
                      onChange={this.handleInputChangeFor("tripDestination")}
                      inputProps={{
                        name: "tripDestination",
                        id: "tripDestination"
                      }}
                      className="profileSelect"
                      classes={{ select: classes.locationSelect }}
                      displayEmpty
                      disableUnderline
                      width="100%"
                    >
                      <MenuItem value="" disabled>
                        Select an option
                    </MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"AU"}>Australia</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"CA"}>Canada</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"DE"}>Germany</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"GR"}>Greece</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"HK"}>Hong Kong</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IS"}>Iceland</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IN"}>India</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ID"}>Indonesia</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IE"}>Ireland</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"IT"}>Italy</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"JP"}>Japan</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"MX"}>Mexico</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"NZ"}>New Zealand</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"PH"}>Phillipppines</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"PT"}>Portugal</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"ES"}>Spain</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"TH"}>Thailand</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"GB"}>United Kingdom</MenuItem>
                      <MenuItem classes={{ root: classes.menuRoot }} value={"US"}>United States</MenuItem>
                    </Select>
                  </FormControl>

                  {this.state.tripDestination === "US" &&
                    <FormControl id="stateSelect" className={classes.formControl}>
                      <Select
                        className="profileSelect"
                        // styles={selectStyle}
                        displayEmpty
                        classes={{ select: classes.select }}
                        disableUnderline
                        value={this.state.state}
                        onChange={this.handleInputChangeFor("state")}
                      >
                        <MenuItem classes={{ root: classes.menuRoot }} value="" disabled>State</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"AL"}>AL</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"AK"}>AK</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"AR"}>AR</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"CA"}>CA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"CO"}>CO</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"CT"}>CT</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"DE"}>DE</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"FL"}>FL</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"GA"}>GA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"HI"}>HI</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"ID"}>ID</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"IL"}>IL</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"IN"}>IN</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"IA"}>IA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"KS"}>KS</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"KY"}>KY</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"LA"}>LA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"ME"}>ME</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MD"}>MD</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MA"}>MA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MI"}>MI</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MN"}>MN</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MS"}>MS</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MO"}>MO</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"MT"}>MT</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"ME"}>ME</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NE"}>NE</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NV"}>NV</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NH"}>NH</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NJ"}>NJ</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NJ"}>NJ</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NM"}>NM</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NY"}>NY</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"NC"}>NC</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"ND"}>ND</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"OH"}>OH</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"OK"}>OK</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"OR"}>OR</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"PA"}>PA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"RI"}>RI</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"SC"}>SC</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"SD"}>SD</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"TN"}>TN</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"TX"}>TX</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"UT"}>UT</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"VT"}>VT</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"VA"}>VA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"WA"}>WA</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"WV"}>WV</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"WI"}>WI</MenuItem>
                        <MenuItem classes={{ root: classes.menuRoot }} value={"WY"}>WY</MenuItem>
                      </Select>
                    </FormControl>

                  }
                </div>
              </form>
              <Button
                size="medium"
                onClick={this.handleNextClick}
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: '75px',
                }}
                classes={{ label: classes.label, root: classes.buttonRoot }}
                color="secondary"
              >
                Next
                  <ArrowForward classes={{ root: classes.arrowRoot }} />
              </Button>
            </div>
          </MuiThemeProvider>}
      </div>
    );
  }
}

Location.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  currentTrip: state.currentTrip
});

export default connect(mapStateToProps)(withStyles(styles)(Location));

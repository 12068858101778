import React, { Component } from "react";
import { connect } from "react-redux";
import "./ChooseDates.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import KnownDates from "../KnownDates/KnownDates";
import Dates from "../Dates/Dates";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none"
  },
  select: {
    color: "#252947",
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    margin: "0",
    padding: "0"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  formControlLabel: {
    margin: "auto"
  }
};

class ChooseDates extends Component {
  componentDidMount() {
    console.log(this.props.currentTrip)
    this.props.dispatch({
      type: "GET_TRIP_BY_ID", payload: {
        planner_id: this.props.user.id,
        tripName: this.props.currentTrip.tripName,
        tripType: this.props.currentTrip.tripType
      }
    });
  }

  state = {
    knowDates: ""
  };

  previous = () => {
    this.props.history.push("/location");
  };

  handleChange = event => {
    this.setState({ knowDates: event.target.value });
  };

  handleDateChangeFor = option => date => {
    this.setState({
      [option]: date
    });
  };

  handleChangeCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
    console.log("changing input!");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        {!this.props.currentTrip.tripName.length ? <p>loading...</p> :
          <MuiThemeProvider theme={theme}>
            <Header />
            <div
              style={{
                marginTop: "20vh"
              }}
            >
              <Button
                value="submit"
                type="submit"
                size="medium"
                onClick={this.previous}
                style={{
                  float: "left",
                  background: "none",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "23px",
                  width: "60px",
                  color: "#7eb8c4",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginBottom: "2vh",
                  marginLeft: "146px"
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                <ArrowBack classes={{ root: classes.arrowRoot }} />
                Back
            </Button>
              <div className="infoDiv">

                {/* If emily wants a radio type yes or no */}
                <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                  Have you decided on your trip dates?
                </Typography>
              </div>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                style={{
                  backgroundColor: "white",
                  display: "inline-block"
                }}
              >
                <RadioGroup
                  aria-label="Dates"
                  name="dates"
                  id="confirmDatesRadioGroup"
                  value={this.state.knowDates}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    className={classes.formControlLabel}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    className={classes.formControlLabel}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
              {this.state.knowDates === "yes" && <KnownDates />}
              {this.state.knowDates === "no" && <Dates />}
            </div>
          </MuiThemeProvider>}
      </div>
    );
  }
}

ChooseDates.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  currentTrip: state.currentTrip,
  tripByProject: state.tripByProject,
});

export default connect(mapStateToProps)(withStyles(styles)(ChooseDates));

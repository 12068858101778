const tripByProject = (state = {}, action) => {
    switch (action.type) {
      case 'SET_TRIP_BY_PROJECT':
        return  action.payload;
      case 'CLEAR_TRIP_BY_PROJECT':
        return {};
      default:
        return state;
    }
  };
  
  // user will be on the redux state at:
  // state.user
  export default tripByProject;
  
import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddUser.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import AutoSuggestInput from "../AutoSuggestInput/AutoSuggestInput";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  label: {
    textTransform: "none"
  },
  formControl: {
    width: "100%"
  },
  textField: {
    width: "100%",
    textAlign: "center"
  }
};

class AddUser extends Component {
  state = {
    countryOfPassport: "",
    homeAirport: ""
  };

  handleAddUserClick = () => {
    this.props.history.push("/travelers");
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#F16B52"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Add Travelers
            </Typography>

              <p className="pageCaption" id="captionSpacing">
                Do you friends already have an account? No problem. It’s easy to add travel partners directly from our travel community to your trip. Search the asugo directory to add them:
              <br></br>
                <br></br>
                <strong><em>
                  If you can’t find them, click the invite user button to invite them now!
              </em></strong>
              </p>
            </div>
            <form autoComplete="off" className="userForms">
              <div style={{ display: "inline-flex", marginTop: "100px", maxWidth: "570px" }}>
                <AutoSuggestInput />
                &emsp;
                  <Button
                  value="submit"
                  type="submit"
                  size="medium"
                  onClick={this.handleAddUserClick}
                  variant="contained"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    width: "120px",
                    height: "48px",
                    marginTop: "2px"
                  }}
                  classes={{ label: classes.label }}
                  color="primary"
                >
                  Invite user
                  </Button>
              </div>
            </form>
            <Button
              value="submit"
              type="submit"
              size="medium"
              onClick={this.handleAddUserClick}
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "75px",
                marginBottom: "272px"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              Finish
            </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

AddUser.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(AddUser));


import React, { Component } from "react";
import { connect } from "react-redux";
import "./Dates.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import ArrowForward from "@material-ui/icons/ArrowForward";
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { withRouter } from "react-router-dom";
import Moment from 'react-moment';


const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    height: "4.49%",
    width: "570px",
    fontSize: "36px",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none"
  },
  select: {
    // color: "#CBCCD4",
    color: "#252947",
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    margin: "0",
    padding: "0"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  }
};

class Dates extends Component {
  state = {
    tripDestination: "",
    departOption1: new Date(),
    returnOption1: new Date(),
    departOption2: new Date(),
    returnOption2: new Date(),
    departOption3: new Date(),
    returnOption3: new Date(),
  };

  previous = () => {
    this.props.history.push("/plan-trip");
  };

  handleNextClick = () => {
    console.log("ADDING PLANNER TO TRIP!", this.props.tripByProject);
    const history = this.props;
    // Add current user to Trip
    this.props.dispatch({
      type: "ADD_PLANNER_TO_TRIP",
      payload: {
        trip_id: this.props.tripByProject[0].id,
        user_id: this.props.tripByProject[0].planner_id,
        username: this.props.user.username,
        historyProps: history
      }
    });
    // prep for Add date options with user and trip id
    // this.props.dispatch({
    //   type: "ADD_DATES_TO_POLL",
    //   payload: {
    //     trip_id: this.props.tripByProject[0].id,
    //     user_id: this.props.tripByProject[0].planner_id,
    //     departOption1: this.state.departOption1,
    //     returnOption1: this.state.returnOption1,
    //     departOption2: this.state.departOption2,
    //     returnOption2: this.state.returnOption2,
    //     departOption3: this.state.departOption3,
    //     returnOption3: this.state.returnOption3,
    //   }
    // })
  };

  handleDateChangeFor = option => date => {
    // let formattedDate = Moment(date).format('YYYYMMDD');
    this.setState({
      [option]: date
    });
    console.log(this.state);
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="datesHeader">
            <div className="infoDiv">
              <p className="datesCaption">
                Choose the dates that work best for you to go. You can enter up to
                three options that other travelers will be able to see when they
                login to the trip.
                </p>
            </div>
            <form autoComplete="off" className="userForms" id="dateForm">
              <div className="locationDiv">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <p className="dateOptions" id="dateOptionOne">Option 1</p>
                  <h5 className="userInfoLabel">Depart</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.departOption1}

                    onChange={this.handleDateChangeFor("departOption1")}
                  />
                  <h5 className="userInfoLabel">Return</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.returnOption1}
                    onChange={this.handleDateChangeFor("returnOption1")}
                  />
                  <p className="dateOptions">Option 2</p>
                  <h5 className="userInfoLabel">Depart</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.departOption2}
                    onChange={this.handleDateChangeFor("departOption2")}
                  />
                  <h5 className="userInfoLabel">Return</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.returnOption2}
                    onChange={this.handleDateChangeFor("returnOption2")}
                  />
                  <p className="dateOptions">Option 3</p>
                  <h5 className="userInfoLabel">Depart</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.departOption3}
                    onChange={this.handleDateChangeFor("departOption3")}
                  />
                  <h5 className="userInfoLabel">Return</h5>
                  <DatePicker
                    margin="normal"
                    label="Select a date"
                    value={this.state.returnOption3}
                    onChange={this.handleDateChangeFor("returnOption3")}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </form>
            <Button
              size="medium"
              onClick={this.handleNextClick}
              variant="contained"
              style={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "75px",
                marginBottom: "306px",
              }}
              classes={{ label: classes.label, root: classes.buttonRoot }}
              color="secondary"
            >
              Next
              <ArrowForward classes={{ root: classes.arrowRoot }} />
            </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Dates.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tripByProject: state.tripByProject

});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Dates))
);

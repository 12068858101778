import { put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';

// worker Saga: will be fired on 'SEND_TRIP_DATA' actions
function* addTrip(action) {
    console.log('adding trip info!');
  try {
    
    // adds trip information to database
    yield axios.post('api/trip/', action.payload);
  } catch (error) {
      console.log('Error with adding trip information:', error);
  }
}

// worker Saga: will be fired on 'ADD_PLANNER_TO_TRIP' actions
function* addPlannerToTrip(action) {
    console.log('adding planner to trip!');
  try {
    const historyProps = action.payload.historyProps
    // adds trip information to database
    yield axios.post('api/trip/add-planner', action.payload);
    historyProps.history.push("/travelers")
  } catch (error) {
      console.log('Error with adding planner to trip:', error);
      // alert("Oops! That user is already on this trip.")
  }
}

// worker Saga: will be fired on 'ADD_USER_TO_EXISTING_TRIP' actions
function* addUserToExistingTrip(action) {
  try {
    const historyProps = action.payload.historyProps
    // adds trip information to database
    yield axios.post('api/trip/add-planner', action.payload);
    historyProps.history.push("/travelers")
  } catch (error) {
      console.log('Error with adding existing user to trip:', error);
      alert("Oops! That user is already on this trip. Try another user!")
  }
}

// worker Saga: will be fired on 'GET_TRIP_BY_ID' actions
function* getTripById(action) {
  let planner_id = action.payload.planner_id;
  let tripName = action.payload.tripName;
  let tripType = action.payload.tripType;
  
  try {
    const trip = yield axios.get(`/api/trip/${planner_id}/${tripName}/${tripType}`)
    yield put({ type: 'SET_TRIP_BY_PROJECT', payload: trip.data });

  } catch (error) {
    console.log('error getting trip by id:', error);
  }
}

// worker Saga: will be fired on 'GET_TRIP_BY_ID' actions
function* getUsersByTripId(action) {
  let trip_id = action.payload.trip_id;
  console.log("here's the trip id! :", trip_id);
  
  try {
    const trip = yield axios.get(`/api/trip/${trip_id}`)
    yield put({ type: 'SET_USERS_BY_TRIP', payload: trip.data });
    console.log("HERE'S THE TRIP DATA FROM USERS BY TRIP", trip.data)
  } catch (error) {
    console.log('error getting users by trip:', error);
  }
}

function* tripSaga() {
  yield takeLatest('SEND_TRIP_DATA', addTrip);
  yield takeLatest('GET_TRIP_BY_ID', getTripById);
  yield takeLatest('GET_USERS_BY_TRIP_ID', getUsersByTripId);
  yield takeLatest('ADD_PLANNER_TO_TRIP', addPlannerToTrip);
  yield takeLatest('ADD_USER_TO_EXISTING_TRIP', addUserToExistingTrip);
}

export default tripSaga;

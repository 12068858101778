import React, { Component } from "react";
import { connect } from "react-redux";
import "./Register.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  label: {
    textTransform: "none"
  }
};

class Register extends Component {
  state = {
    firstName: "",
    middleName: "",
    lastName: "",
    username: "",
    password: ""
  };

  registerUser = event => {
    event.preventDefault();

    if (this.state.username && this.state.password) {
      this.props.dispatch({
        type: "REGISTER",
        payload: {
          firstName: this.state.firstName,
          middleName: this.state.middleName,
          lastName: this.state.lastName,
          username: this.state.username,
          password: this.state.password
        }
      });
      this.props.history.push("/traveler-profile");
    } else {
      this.props.dispatch({ type: "REGISTRATION_INPUT_ERROR" });
    }
  }; // end registerUser

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  handleFinishClick = () => {
    this.props.history.push("/traveler-info");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        {this.props.errors.registrationMessage && (
          <h4 className="alert" role="alert">
            {this.props.errors.registrationMessage}
          </h4>
        )}
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Create Account
            </Typography>
              <p className="pageCaption">
                Create account or login below if an existing user.
            </p>
            </div>
            <form onSubmit={this.registerUser} className="userForms">
              <div className="inputDiv">
                <h5 className="userInfoLabel">Email</h5>
                <input
                  required
                  type="email"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.username}
                  onChange={this.handleInputChangeFor("username")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Password</h5>
                <input
                  required
                  type="password"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.password}
                  onChange={this.handleInputChangeFor("password")}
                />
              </div>
              <Button
                type="submit"
                name="submit"
                size="medium"
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: "45px",
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Create Account
              </Button>
            </form>
            <br />
            <center>
              <button
                type="button"
                className="link-button"
                onClick={() => {
                  this.props.dispatch({ type: "SET_TO_LOGIN_MODE" });
                }}
                size="small"
                variant="outlined"
                style={{ marginBottom: "127px" }}
              >
                Already have an account? Sign in here.
              </button>
            </center>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired
};

// Instead of taking everything from state, we just want the error messages.
const mapStateToProps = state => ({
  errors: state.errors
});

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(Register))
);

import React, { Component } from "react";
import { connect } from "react-redux";
import "./TravelProfileForm.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TravelerInfo from "./TravelerInfo/TravelerInfo";
import ContactInfo from "./ContactInfo/ContactInfo";
import MobileStepper from "@material-ui/core/MobileStepper";

const styles = {
  mobileStepperOne: {
    background: "none",
    marginTop: "180px",
    marginBottom: "110px"
  },
  mobileStepperTwo: {
    background: "none",
    marginTop: "140px"
  },
  dot: {
    height: "15px",
    width: "15px",
    backgroundColor: "#c8c9d1"
  },
  dotActive: {
    backgroundColor: "#f16b52"
  },
  dots: {
    margin: "auto",
    width: "43px"
  }
};

class TravelerProfileForm extends Component {
  state = {
    step: 1,
    open: true,
    confirmationOpen: false,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    birthMonth: "",
    birthDay: "",
    birthYear: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    phoneNumber: "",
    state: "",
    zipCode: ""
  };

  //   Proceeds to next Step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  //   Go back to previous Step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  //   handle fields change
  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmationClose = () => {
    this.setState({ confirmationOpen: false });
  };

  handleConfirmationOpen = (event) => {
    event.preventDefault();
    this.setState({ confirmationOpen: true });
  };

  handleSubmitClick = (event) => {
    event.preventDefault();
    this.props.dispatch({
      type: 'ADD_TRAVEL_PROFILE',
      payload: {
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        gender: this.state.gender,
        birthMonth: this.state.birthMonth,
        birthDay: this.state.birthDay,
        birthYear: this.state.birthYear,
        phoneNumber: this.state.phoneNumber,
        addressLineOne: this.state.addressLineOne,
        addressLineTwo: this.state.addressLineTwo,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        id: this.props.user.id
      }
    });
    this.props.history.push("/traveler-preferences")
  }

  render() {
    const { classes } = this.props;
    const { step } = this.state;
    const {
      firstName,
      middleName,
      lastName,
      gender,
      birthMonth,
      birthDay,
      birthYear,
      phoneNumber,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode
    } = this.state;

    const values = {
      firstName,
      middleName,
      lastName,
      gender,
      birthMonth,
      birthDay,
      birthYear,
      phoneNumber,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode
    };

    switch (step) {
      case 1:
        return (
          <div>
            <TravelerInfo
              nextStep={this.nextStep}
              handleInputChangeFor={this.handleInputChangeFor}
              values={values}
              open={this.state.open}
              handleClose={this.handleClose}
            />
            <MobileStepper
              steps={2}
              position="static"
              activeStep={this.step}
              className={classes.mobileStepperOne}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );

      case 2:
        return (
          <div>
            <ContactInfo
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleInputChangeFor={this.handleInputChangeFor}
              values={values}
              confirmationOpen={this.state.confirmationOpen}
              handleConfirmationClose={this.handleConfirmationClose}
              handleConfirmationOpen={this.handleConfirmationOpen}
              handleSubmitClick={this.handleSubmitClick}
            />
            <MobileStepper
              steps={2}
              position="static"
              activeStep={1}
              className={classes.mobileStepperTwo}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots
              }}
              variant="dots"
            />
          </div>
        );

      default:
        break;
    }
  }
}

TravelerProfileForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(
  withStyles(styles)(TravelerProfileForm)
);

import React from "react";
import "./AutoSuggestInput.css";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";


const styles = {
  container: {
    position: "relative"
  },
  input: {
    height: "3.125rem",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    border: "transparent",
    backgroundColor: "#f4f4f6"
  },
  inputFocused: {
    outline: "none"
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  label: {
    textTransform: "none"
  }
};

const desktopStyles = {
  container: {
    position: "relative"
  },
  input: {
    width: "350px",
    height: "3.125rem",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    border: "transparent",
    backgroundColor: "#f4f4f6"
  },
  inputFocused: {
    outline: "none"
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  label: {
    textTransform: "none"
  }
};

class AutoSuggestInput extends React.Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      suggestions: []
    };
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.users.filter(
        users => users.username.toLowerCase().slice(0, inputLength) === inputValue
      );
  };


  handleAddToTripClick = (user_id, username) => (event) => {
    event.preventDefault();
    const history = this.props;
    this.props.dispatch({
      type: 'ADD_USER_TO_EXISTING_TRIP',
      payload: {
        trip_id: this.props.tripByProject[0].id,
        user_id: user_id,
        username: username,
        historyProps: history
      },
    });
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => suggestion.username;

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion =>
    <div>
      {suggestion.username}
      &emsp;
      <Button onClick={this.handleAddToTripClick(suggestion.id, suggestion.username)} style={{ textTransform: "none", fontFamily: "Poppins", width: "120px" }} color="primary" variant="contained">Add to Trip!</Button>
    </div>;

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "search by email",
      value,
      onChange: this.onChange
    };

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#F16B52"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    // Finally, render it!
    return (
      <MuiThemeProvider theme={theme}>
        <MediaQuery query="(max-width: 1024px)">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            style={{ height: "3.125rem", backgroundColor: "#f4f4f6", maxWidth: "570px", width: "100%" }}
            theme={styles}
          />
        </MediaQuery>
        <MediaQuery query="(min-width: 1024.1px)">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            style={{ height: "3.125rem", backgroundColor: "#f4f4f6", maxWidth: "570px", width: "100%" }}
            theme={desktopStyles}
          />
        </MediaQuery>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  users: state.users,
  tripByProject: state.tripByProject,
});

export default connect(mapStateToProps)(
  withRouter(AutoSuggestInput));

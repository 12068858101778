import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Header from "../Header/Header";
import Typography from "@material-ui/core/Typography";


const styles = {
    root: {
      flexGrow: 1
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    h4: {
      marginBottom: "19px",
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#252947",
      height: "46px",
      fontSize: "2.25rem",
      lineHeight: "50px",
      textAlign: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "570px"
    },
    label: {
      textTransform: "none"
    }
  };

class LoginPage extends Component {
  state = {
    username: '',
    password: '',
  };

  login = (event) => {
    event.preventDefault();

    if (this.state.username && this.state.password) {
      this.props.dispatch({
        type: 'LOGIN',
        payload: {
          username: this.state.username,
          password: this.state.password,
        },
      });
      this.props.history.push('/welcome')
    } else {
      this.props.dispatch({ type: 'LOGIN_INPUT_ERROR' });
    }
  } // end login

  handleInputChangeFor = propertyName => (event) => {
    this.setState({
      [propertyName]: event.target.value,
    });
  }

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className="pageHeader">
        <MuiThemeProvider theme={theme}>
          <Header />
            <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
              Login
            </Typography>
            <p className="pageCaption">
              Enter email and password to login!
            </p>
            <form onSubmit={this.login} className="userForms">
              <div className="inputDiv">
                <h5 className="userInfoLabel">Email</h5>
                <input
                  required
                  type="email"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.username}
                  onChange={this.handleInputChangeFor("username")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Password</h5>
                <input
                  required
                  type="password"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.password}
                  onChange={this.handleInputChangeFor("password")}
                />
              </div>
              <Button
                type="submit"
                name="submit"
                size="medium"
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginTop: "45px",
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Sign In
              </Button>
            </form>
            <br />
            <center>
              <button
                type="button"
                className="link-button"
                onClick={() => {
                  this.props.dispatch({ type: "SET_TO_REGISTER_MODE" });
                }}
                size="small"
                variant="outlined"
                style={{marginBottom: "127px", fontFamily: "Poppins",}}
              >
                Don't have an account?
              </button>
            </center>
        </MuiThemeProvider>
      </div>
    );
  }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired
  };

// Instead of taking everything from state, we just want the error messages.
// if you wanted you could write this code like this:
// const mapStateToProps = ({errors}) => ({ errors });
const mapStateToProps = state => ({
  errors: state.errors,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LoginPage)));
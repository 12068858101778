import { combineReducers } from 'redux';
import errors from './errorsReducer';
import loginMode from './loginModeReducer';
import user from './userReducer';
import users from './usersReducer';
import travelProfile from './travelProfileReducer';
import trip from './tripReducer';
import currentTrip from './currentTripReducer';
import currentTripId from './currentTripIdReducer';
import tripByProject from './tripByProjectReducer';
import usersByTrip from './usersByTripReducer';
// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootSaga

// Lets make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside of 'mapStateToProps'
const rootReducer = combineReducers({
  errors, // contains registrationMessage and loginMessage
  loginMode, // will have a value of 'login' or 'registration' to control which screen is shown
  user, // will have an id and username if someone is logged in
  users, // gather users emails for autocomplete
  travelProfile, 
  trip, // will store trip data in redux state
  currentTrip, // will store current trip data in redux state
  currentTripId, // will store current trip id in redux state
  tripByProject, // will store current trip by project data in redux state
  usersByTrip, // will store current trip by project data in redux state
});

export default rootReducer;

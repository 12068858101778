import React, { Component } from "react";
import { connect } from "react-redux";
import "./TransportationPreferences.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";

const styles = {
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    h4: {
        marginBottom: "5vh",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "bold",
        color: "#252947",
        fontSize: "2.25rem",
        lineHeight: "50px",
        textAlign: "center",
        margin: "auto",
        width: "100%",
        maxWidth: "570px"
    },
    formControl: {
        width: "100%"
    },
    radioRoot: {
        display: "none",
        backgroundColor: "#FFFFFF"
    },
    label: {
        textTransform: "none"
    },
    arrowRoot: {
        width: "20px",
        height: "16px",
        marginLeft: "3px"
    },
    formLabel: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
    }
};

class TransportationPreferences extends Component {
    state = {
        rewardMember: null,
    };

    previous = event => {
        event.preventDefault();
        this.props.prevStep();
    };

    continue = event => {
        event.preventDefault();
        this.props.nextStep();
    };

    handleChange = event => {
        this.setState({ value: event.target.value });
    };

    handleNextClick = () => {
        this.props.history.push("/plan-trip");
    };

    previous = event => {
        event.preventDefault();
        this.props.prevStep();
    };

    continue = event => {
        event.preventDefault();
        this.props.nextStep();
    };

    render() {
        const { values } = this.props;
        const { classes } = this.props;

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#252947"
                },

                secondary: {
                    main: "#7EB8C4"
                }
            },
            typography: {
                useNextVariants: true
            }
        });

        return (
            <div className={classes.root}>
                <MuiThemeProvider theme={theme}>
                    <Header />
                    <div className="pageHeader">
                        <Button
                            onClick={this.previous}
                            style={{
                                float: "left",
                                background: "none",
                                fontWeight: "bold",
                                fontFamily: "Poppins",
                                display: "inline-flex",
                                alignItems: "center",
                                margin: "auto",
                                borderRadius: "25px",
                                height: "23px",
                                width: "60px",
                                color: "#7eb8c4",
                                fontSize: "16px",
                                lineHeight: "16px",
                                textAlign: "center",
                                marginBottom: "2vh",
                                marginLeft: "10vw"
                            }}
                            classes={{ label: classes.label }}
                            color="secondary"
                        >
                            <ArrowBack classes={{ root: classes.arrowRoot }} />
                            Back
                        </Button>
                        <div className="infoDiv">
                            <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                                Set Transportation Preferences
                            </Typography>

                            <p className="pageCaption">
                                You can adjust your transportation preferences based on each trip you plan. This is a benchmark to get a general idea for how you prefer to get around once you are at your travel destination.
                            </p>
                        </div>
                        <div className="userForms">

                            <FormGroup row className="accomodationCheckBoxes">
                                <div className="accomodationCheckBoxes" align="left">
                                    <FormControlLabel
                                        classes={{ label: classes.formLabel }}
                                        control={
                                            <Checkbox
                                                checked={values.walking}
                                                onChange={this.props.handleChangeCheckBox(
                                                    "walking"
                                                )}
                                                value="walking"
                                                color="secondary"
                                            />
                                        }
                                        label="Walking"
                                    />
                                    <br></br>
                                    <FormControlLabel
                                        classes={{ label: classes.formLabel }}
                                        control={
                                            <Checkbox
                                                checked={values.publicTransit}
                                                onChange={this.props.handleChangeCheckBox("publicTransit")}
                                                value="Public Transit"
                                                color="secondary"
                                            />
                                        }
                                        label="Public Transit"
                                    />
                                    <br></br>
                                    <FormControlLabel
                                        classes={{ label: classes.formLabel }}
                                        control={
                                            <Checkbox
                                                checked={values.rideSharing}
                                                onChange={this.props.handleChangeCheckBox("rideSharing")}
                                                value="Ride Sharing"
                                                color="secondary"
                                            />
                                        }
                                        label="Ridesharing"
                                    />
                                    <br></br>
                                    <FormControlLabel
                                        classes={{ label: classes.formLabel }}
                                        control={
                                            <Checkbox
                                                checked={values.privateDrivers}
                                                onChange={this.props.handleChangeCheckBox("privateDrivers")}
                                                value="Private Drivers"
                                                color="secondary"
                                            />
                                        }
                                        label="Private Drivers"
                                    />
                                </div>
                            </FormGroup>

                        </div>
                        <pre />
                        <Button
                            value="submit"
                            type="submit"
                            size="medium"
                            onClick={this.continue}
                            variant="contained"
                            style={{
                                fontWeight: "bold",
                                fontFamily: "Poppins",
                                display: "inline-flex",
                                alignItems: "center",
                                margin: "auto",
                                borderRadius: "25px",
                                height: "50px",
                                width: "168px",
                                color: "#FFFFFF",
                                fontSize: "16px",
                                lineHeight: "16px",
                                textAlign: "center",
                                marginTop: "30px",
                                marginBottom: "25px"
                            }}
                            classes={{ label: classes.label }}
                            color="secondary"
                        >
                            Next
                <ArrowForward classes={{ root: classes.arrowRoot }} />
                        </Button>

                    </div>
                </MuiThemeProvider>
            </div >
        );
    }
}

TransportationPreferences.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(
    withRouter(withStyles(styles)(TransportationPreferences))
);

import React, { Component } from "react";
import "./Success.css";
import Header from "../Header/Header";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  h4: {
    marginBottom: "19px",
    marginTop: "15vh",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    height: "46px",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  label: {
    textTransform: "none"
  },
};

class Success extends Component {
  handleRestartClick = () => {
    this.props.history.push("/plan-trip");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
          <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
              Success!
            </Typography>
            <p className="pageCaption">
              Your trip is now ready to be booked.
              <br />
              Click below to plan another!
            </p>
            <br />
            <Button
              value="submit"
              type="submit"
              size="medium"
              onClick={this.handleRestartClick}
              variant="contained"
              style={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                display: "inline-flex",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginTop: "25px",
                marginBottom: "272px"
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              Start
            </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Success);

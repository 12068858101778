import React, { Component } from "react";
import "./AddTraveler.css";
import Header from "../Header/Header";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    height: "46px",
    width: "570px",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  iconRoot: {
    width: "25px",
    height: "25px"
  },
  label: {
    textTransform: "none",
    color: "#f16b52"
  },
  buttonRoot: {
    padding: "0",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#f16b52",
    display: "inline-block"
  },
  completeLabel: {
    textTransform: "none"
  },
  checkMarkRoot: {
    height: "23px",
    verticalAlign: "center",
    color: "#f16b52",
    marginTop: "10px"
  }
};

class AddTraveler extends Component {
  handleAddNewTraveler = () => {
    this.props.history.push("/add-user");
  };

  handleInviteTraveler = () => {
    this.props.history.push("/invite-traveler");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#FAFAFA"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root} align="left">
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
              Add Travelers
            </Typography>
            <p className="pageCaption">
              Add new travelers to the platform or invite an existing user to
              your trip!
            </p>

            <div className="travelers">
              <Button
                classes={{ label: classes.label, root: classes.buttonRoot }}
                onClick={this.handleAddNewTraveler}
                style={{ marginTop: "50px", fontFamily: "Poppins", }}
              >
                <div className="iconContainer" align="left">
                  <span className="userName">Add Asugo User</span>

                  <ArrowForward
                    className="checkMark"
                    classes={{ root: classes.checkMarkRoot }}
                  />
                </div>
              </Button>
              <Button
                classes={{ label: classes.label, root: classes.buttonRoot }}
                onClick={this.handleInviteTraveler}
              >
                <div className="iconContainer" align="left">
                  <span className="userName">Invite Traveler</span>

                  <ArrowForward
                    className="checkMark"
                    classes={{ root: classes.checkMarkRoot }}
                  />
                </div>
              </Button>
            </div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

AddTraveler.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddTraveler);

import React, { Component } from "react";
import "./Welcome.css";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  label: {
    textTransform: "none"
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    height: "46px",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  h5: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    paddingTop: "2vh"
  },
  body2: {
    color: "#252947"
  }
};

class Welcome extends Component {
  handleGetStartedClick = () => {
    this.props.history.push("/traveler-profile");
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <div className="welcomeHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Welcome!
            </Typography>
              <p className="welcomeText">
                So you want to plan a trip?
              <br></br>
                <br></br>
                Perfect, we’re here to make the
                planning process easy for you as you go on your next adventure.
              <br></br>
                <br></br>
                Our platform helps you stay organized, make decisions, and have
                fun throughout the planning process. Not to mention the amazing
                travel tips and recommendations you’ll receive along the way.
              <br></br>
                <br></br>
                Here’s how it works:
              <br></br>
                1: Create your Traveler Profile + Preferences
              <br></br>
                2: Set your travel behaviors and preferences
              <br></br>
                3: Connect with all of your Travel Partners
              <br></br>
                4: Invite your travel partners to join our traveler
                community and setup their profile and preferences.
              <br></br>
                5: Start Planning Your Next Trip Together
              <br></br>
                <br></br>
                We’ll help you optimize your trip so that
                everyone is happy!
              <br></br>
                Don’t worry, we’re not going anywhere.
              <br></br>
                <br></br>
                We’re on
                this journey with you, providing travel tips, checklists,
                recommendations, and ideas to make trip planning easy and fun.
              </p>
            </div>
            <pre />
            <Button
              size="medium"
              onClick={this.handleGetStartedClick}
              variant="contained"
              style={{
                fontWeight: "bold",
                display: "inline-block",
                alignItems: "center",
                margin: "auto",
                borderRadius: "25px",
                height: "50px",
                width: "168px",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                marginBottom: "8vh",
              }}
              classes={{ label: classes.label }}
              color="secondary"
            >
              Get Started
                </Button>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Welcome);

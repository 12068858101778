import React, { Component } from "react";
import "./InviteTraveler.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%"
  },
  label: {
    textTransform: "none"
  }
};

class InviteTraveler extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: ""
  };
  handleSendClick = () => {
    this.props.history.push("/plan-trip");
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="pageHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Invite Travelers
              </Typography>
              <p className="datesCaption" style={{}}>
                Invite your travel partners into the app! For each pal who joins using your unique link, you’ll receive $5 in travel credit to your account.
              </p>
            </div>
            <form autoComplete="off" className="userForms">
              <div className="inputDiv">
                <h5 className="userInfoLabel">First Name</h5>
                <input
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.firstName}
                  onChange={this.handleInputChangeFor("firstName")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Last Name</h5>
                <input
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.lastName}
                  onChange={this.handleInputChangeFor("lastName")}
                />
              </div>
              <div className="inputDiv">
                <h5 className="userInfoLabel">Email</h5>
                <input
                  type="text"
                  style={{ fontSize: "17px" }}
                  className="infoInput"
                  value={this.state.email}
                  onChange={this.handleInputChangeFor("email")}
                />
              </div>

              <pre />
              <Button
                size="medium"
                onClick={this.handleSendClick}
                variant="contained"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "auto",
                  borderRadius: "25px",
                  height: "50px",
                  width: "168px",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  lineHeight: "16px",
                  textAlign: "center",
                  marginBottom: "13vh",
                  marginTop: "6vh",
                }}
                classes={{ label: classes.label }}
                color="secondary"
              >
                Send
              </Button>
            </form>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

InviteTraveler.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InviteTraveler);

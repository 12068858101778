import { takeLatest } from 'redux-saga/effects';
import axios from 'axios';

// worker Saga: will be fired on 'ADD_TRAVEL_PROFILE' actions
function* addProfile(action) {
  try {
    // adds travel profile information to database
    yield axios.post('api/traveler/profile', action.payload);
  } catch (error) {
      console.log('Error with adding travel profile:', error);
  }
}

// worker Saga: will be fired on 'ADD_BUDGET_PREFERENCES' actions
function* addPreferences(action) {
  try {
    // adds travel budget information to database
    yield axios.post('api/traveler/preferences', action.payload);
  } catch (error) {
      console.log('Error with adding travel preferences:', error);
  }
}

function* addAccomodations(action) {
  try {
    // adds travel accomodation information to database
    yield axios.post('api/traveler/accomodations', action.payload);
  } catch (error) {
      console.log('Error with adding accomodation preferences:', error);
  }
}

function* travelProfileSaga() {
  yield takeLatest('ADD_TRAVEL_PROFILE', addProfile);
  yield takeLatest('ADD_TRAVEL_PREFERENCES', addPreferences);
  yield takeLatest('ADD_ACCOMODATION_PREFERENCES', addAccomodations);
}

export default travelProfileSaga;

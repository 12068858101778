import React, { Component } from "react";
import { connect } from "react-redux";
import "./PlanTrip.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Dialog from "../Dialog/Dialog";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  h4: {
    marginBottom: "19px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    color: "#252947",
    fontSize: "2.25rem",
    lineHeight: "50px",
    textAlign: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "570px"
  },
  formControl: {
    width: "100%",
    textAlign: "left",
    fontWeight: "normal",
    backgroundColor: "#f4f4f6"
  },
  label: {
    textTransform: "none"
  },
  formControlDate: {
    align: "left",
    backgroundColor: "#f4f4f6",
    color: "#CBCCD4"
  },
  select: {
    color: "#252947",
    width: "100%",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    margin: "0",
    padding: "0",
    paddingLeft: "12px",
    fontFamily: "Poppins"
  },
  fontSizeSmall: {
    height: "2px",
    width: "2px"
  },
  arrowRoot: {
    width: "20px",
    height: "16px",
    marginLeft: "3px"
  },
  disabled: {
    color: "#CBCCD4",
  },
  menuRoot: {
    fontFamily: "Poppins, sans-serif",
  }
};

class PlanTrip extends Component {
  state = {
    open: true,
    tripName: "",
    tripType: ""
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleNextClick = () => {
    this.props.dispatch({
      type: "SET_CURRENT_TRIP",
      payload: {
        tripName: this.state.tripName,
        tripType: this.state.tripType,
        planner_id: this.props.user.id
      }
    });
    this.props.dispatch({
      type: "ADD_TRIP_DATA",
      payload: {
        tripName: this.state.tripName,
        tripType: this.state.tripType
      }
    });
    this.props.dispatch({
      type: "SEND_TRIP_DATA",
      payload: {
        tripName: this.state.tripName,
        tripType: this.state.tripType,
        planner_id: this.props.user.id
      }
    });
    this.props.history.push("/location");
  };

  handleInputChangeFor = propertyName => event => {
    this.setState({
      [propertyName]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const title = "Plan a Trip";
    const buttonText = "Next";
    const contentText = `Trips are like snowflakes. They’re all unique in their own way. As you plan upcoming trips, we’ll assign a 
    unique Trip ID to each one so that you can centralize the trip-specific details 
    in one dashboard and help everyone stay organized. `;
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: "#252947"
        },

        secondary: {
          main: "#7EB8C4"
        }
      },
      typography: {
        useNextVariants: true
      }
    });

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header />
          <Dialog
            title={title}
            open={this.state.open}
            handleClose={this.handleClose}
            buttonText={buttonText}
            contentText={contentText}
          />
          <div className="pageHeader">
            <div className="infoDiv">

              <Typography variant="h4" gutterBottom classes={{ h4: classes.h4 }}>
                Plan a Trip
            </Typography>
            </div>
            <form
              autoComplete="off"
              id="travelerInfoForm"
              className="userForms"
            >
              <div className="inputDiv">
                <h5 className="userInfoLabel">First things first, what is the name of your trip?</h5>
                <input
                  type="text"
                  style={{ fontSize: "16px" }}
                  className="infoInput"
                  value={this.state.tripName}
                  onChange={this.handleInputChangeFor("tripName")}
                  required
                />
              </div>
              <div className="genderDiv">
                <h5 className="userInfoLabel" style={{ marginTop: "2vh" }}>What is the purpose of your trip?</h5>
                <FormControl className={classes.formControl}>
                  <Select
                    value={this.state.tripType}
                    onChange={this.handleInputChangeFor("tripType")}
                    inputProps={{
                      name: "tripType",
                      id: "tripType"
                    }}
                    className="profileSelect"
                    classes={{ select: classes.select, disabled: classes.disabled }}
                    displayEmpty
                    disableUnderline
                    width="100%"
                  >
                    <MenuItem value="" disabled className="disabled">
                      Select an option
                    </MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"personalVacation"}>Personal Vacation</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"friendVacation"}>Friend Vacation</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"couplesGetaway"}>Couples Getaway</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"bachelor-bachelorette-party"}>Bachelorette/Bachelor Party</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"wedding"}>Wedding</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"honeyMoon"}>Honeymoon</MenuItem>
                    <MenuItem classes={{ root: classes.menuRoot }} value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="nextButton">
                <Button
                  size="medium"
                  onClick={this.handleNextClick}
                  variant="contained"
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    display: "inline-flex",
                    alignItems: "center",
                    margin: "auto",
                    borderRadius: "25px",
                    height: "50px",
                    width: "168px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "16px",
                    textAlign: "center",
                    marginTop: "10vh",
                    marginBottom: "10vh"
                  }}
                  classes={{ label: classes.label }}
                  color="secondary"
                >
                  Next
                  <ArrowForward classes={{ root: classes.arrowRoot }} />
                </Button>
              </div>
            </form>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

PlanTrip.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(PlanTrip));
